const AngleDown = ({ color = "#fff" }) => (
  <svg
    width={18}
    height={12}
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3332 1.28336C17.0209 0.972942 16.5985 0.798706 16.1582 0.798706C15.7179 0.798706 15.2954 0.972942 14.9832 1.28336L8.99983 7.18336L3.09983 1.28336C2.78756 0.972942 2.36514 0.798706 1.92483 0.798706C1.48452 0.798706 1.0621 0.972942 0.749833 1.28336C0.593619 1.4383 0.469628 1.62263 0.385014 1.82573C0.300399 2.02883 0.256836 2.24667 0.256836 2.46669C0.256836 2.68671 0.300399 2.90456 0.385014 3.10765C0.469628 3.31075 0.593619 3.49509 0.749833 3.65003L7.8165 10.7167C7.97144 10.8729 8.15577 10.9969 8.35887 11.0815C8.56197 11.1661 8.77981 11.2097 8.99983 11.2097C9.21985 11.2097 9.4377 11.1661 9.64079 11.0815C9.84389 10.9969 10.0282 10.8729 10.1832 10.7167L17.3332 3.65003C17.4894 3.49509 17.6134 3.31075 17.698 3.10765C17.7826 2.90456 17.8262 2.68671 17.8262 2.46669C17.8262 2.24667 17.7826 2.02883 17.698 1.82573C17.6134 1.62263 17.4894 1.4383 17.3332 1.28336Z"
      fill={color}
    />
  </svg>
)

export default AngleDown
