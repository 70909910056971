import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box;
}
body {
    font-family: "Din", sans-serif;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
`

export default GlobalStyles