import { useState, useLayoutEffect, useRef } from "react"
import { useInView } from "react-intersection-observer"
import { gsap } from "gsap/all";
import styled from "styled-components"

import Avatars from "../components/Avatars"
import ContactForm from "../components/ContactForm"
import AngleDownColumn from "../components/AngleDownColumn"

import useScroll from "../hooks/useScroll"
import { getContextDelayTrigger } from "../helpers/animationHelper"

import shapes from "../images/contact/3DShapes.png"
import shapesMobile from "../images/contact/3DShapesMobile.png"

const Section = styled.section`
  text-align: center;
  height: 1000px;
  padding-top: 190px;
  background:
    radial-gradient(59.41% 92.15% at 87.81% 7.77%, #6C088E 0%, rgba(11, 1, 52, 0.6) 100%),
    radial-gradient(80.66% 83.61% at 5.78% 10.53%, #088E8E 0%, rgba(1, 52, 12, 0.6) 100%),
    radial-gradient(115.33% 100% at 56.09% 100%, #083D8E 0%, #011234 100%);
  mix-blend-mode: normal;
  position: relative;
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 600px;
    padding-top: 72px;
    overflow: hidden;
  }
`
const Title = styled.h2`
  font-size: 80px;
  font-weight: 300;
  line-height: 70px;
  letter-spacing: -2px;
  margin: 0;
  text-transform: uppercase;
  background: linear-gradient(180deg, #FFFFFF -7.91%, #FCCEE1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  span {
    -webkit-text-fill-color: ${props => props.theme.colors.primary};
    font-weight: 700;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 40px;
    line-height: 40px;
  }
`
const Caption = styled.p`
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-top: 22px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 0;
  }
`
const ContactButton = styled.button`
  color: #fff;
  font-family: "Din", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 32px 60px;
  margin-top: 70px;
  border: none;
  background-color: transparent;
  border-radius: 40px;
  box-shadow: -34px 32px 51px rgba(5, 4, 70, 0.34);
  background: linear-gradient(180deg, #FB782F 0%, #F88D2B 23.44%, #FF5C35 100%);
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 24px;
    line-height: 28px;
    padding: 16px 32px;
    margin-top: 25px;
  }
`
const AngleDownColumnContainer = styled.div`
  top: 15px;
  right: 123px;
  position: absolute;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    top: initial;
    right: 46px;
    bottom: 18px;
  }
`
const ShapesLayer = styled.div`
  top: -125px;
  left: 0;
  width: 100%;
  height: 115%;
  overflow: hidden;
  position: absolute;
  user-select: none;
  pointer-events: none;
  z-index: -1;
  img {
    width: 100%;
    &.shapesMobile {
      display: none;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    top: 0;
    img {
      &.shapesDesktop {
        display: none;
      }
      &.shapesMobile {
        display: block;
      }
    }
  }
`
const AvatarsContainer = styled.div`
  opacity: 0.3;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    left: 0;
    right: 0;
    bottom: -36px;
    position: absolute;
  }
`

const ContactSection = () => {
  const [displayContactForm, setDisplayContactForm] = useState(false)

  const containerRef = useRef(null);

  const { handleVisibleSection } = useScroll()
  const { ref, inView, entry } = useInView({
    threshold: .6,
    onChange: (inView, entry) => handleVisibleSection(inView, entry)
  })

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      getContextDelayTrigger(0.5, ".contactFade").from(
        ".contactFade",
        {
          y: 50,
          opacity: 0,
          ease: "power2",
          duration: 1.5,
        }
      );
    }, containerRef);

    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(".shapesDesktop", { yPercent: 0 })

      gsap.to(".shapesDesktop", {
        yPercent: 10,
        ease: "none",
        scrollTrigger: {
          trigger: ".trigger",
          scrub: 1,
        },
      })
    }, containerRef)

    return () => ctx.revert()
  }, [])

  return (
    <Section ref={ref} id="contact">
      <div ref={containerRef}>
        <div className="trigger">
          <Title>
            Is Your Business <br />
            <span className="contactFade">Metaverse</span> Ready?
          </Title>
          <Caption >Let's find out together</Caption>
          {displayContactForm ? (
            <ContactForm />
          ) : (
            <ContactButton type="button" onClick={() => setDisplayContactForm(true)}>
              Contact Us
            </ContactButton>
          )}
          <AvatarsContainer>
            <Avatars />
          </AvatarsContainer>
          <AngleDownColumnContainer>
            <AngleDownColumn items={9} />
          </AngleDownColumnContainer>
          <ShapesLayer>
            <img className="shapesDesktop" src={shapes} alt="" />
            <img className="shapesMobile" src={shapesMobile} alt="" />
          </ShapesLayer>
        </div>
      </div>
    </Section>
  )
}

export default ContactSection
