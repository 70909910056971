import { useLayoutEffect, useRef } from "react"
import { gsap } from "gsap/all";
import styled from "styled-components"

// import { getContextDelay } from "../helpers/animationHelper"

// import avatar1 from "../images/avatars/guy-04-1.svg"
// import avatar2 from "../images/avatars/guy-03-1.svg"
// import avatar3 from "../images/avatars/footer-guy.svg"
// import avatar4 from "../images/avatars/girl-01-1.svg"
// import avatar5 from "../images/avatars/cyberpunk-avatars.svg"
// import avatar6 from "../images/avatars/artisant-pixelord-rpm.svg"
// import avatar7 from "../images/avatars/punk-full-2951.svg"
// import avatar8 from "../images/avatars/personal-3d-avatars-wolf3d-hero.svg"
import avatars from "../images/avatars/avatars.png"

const Container = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 450px;
  max-width: 1280px;
  margin: auto;
  overflow: hidden;
  position: absolute;
  user-select: none;
  pointer-events: none;
  .avatars {
    width: 100%;
    height: 100%;
    background-image: url(${avatars});
    background-repeat: no-repeat;
    background-position: center top;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    /* width: 500px;
    height: 215px; */
    width: 345px;
    height: 185px;
    .avatars {
      background-size: cover;
    }
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 345px;
    height: 185px;
  } */

  /* img {
    position: absolute;
    &.avatar {
      &-1 {
        left: 0;
        bottom: 57px;
      }
      &-2 {
        left: 68px;
        bottom: -43px;
      }
      &-3 {
        left: 148px;
        bottom: -167px;
        z-index: 1;
      }
      &-4 {
        left: 358px;
        bottom: -65px;
        transform: rotate(5.6deg)
      }
      &-5 {
        left: 495px;
        bottom: 5px;
      }
      &-6 {
        right: 243px;
        bottom: -30px;
      }
      &-7 {
        right: 90px;
        bottom: -55px;
      }
      &-8 {
        right: -10px;
        bottom: 40px;
      }
    }
  } */
`

const Avatars = () => {
  const containerRef = useRef(null);
  
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({ delay: 2 })

      tl.from(".avatars", {
        y: 50,
        opacity: 0,
        ease: "power2",
        duration: 1.5,
      })
      // getContextDelay(0.5).from(".avatar-1", {
      //   y: 50,
      //   opacity: 0,
      //   ease: "power2",
      //   duration: 1.5,
      // });
      // getContextDelay(0.5).from(".avatar-8", {
      //   y: 50,
      //   opacity: 0,
      //   ease: "power2",
      //   duration: 1.5,
      // });
      // getContextDelay(0.7).from(".avatar-2", {
      //   y: 50,
      //   opacity: 0,
      //   ease: "power2",
      //   duration: 1.5,
      // });
      // getContextDelay(0.7).from(".avatar-7", {
      //   y: 50,
      //   opacity: 0,
      //   ease: "power2",
      //   duration: 1.5,
      // });
      // getContextDelay(1.3).from(".avatar-3", {
      //   y: 50,
      //   opacity: 0,
      //   ease: "power2",
      //   duration: 1.5,
      // });
      // getContextDelay(1.3).from(".avatar-6", {
      //   y: 50,
      //   opacity: 0,
      //   ease: "power2",
      //   duration: 1.5,
      // });
      // getContextDelay(1.5).from(".avatar-4", {
      //   y: 50,
      //   opacity: 0,
      //   ease: "power2",
      //   duration: 1.5,
      // });
      // getContextDelay(1.5).from(".avatar-5", {
      //   y: 50,
      //   opacity: 0,
      //   ease: "power2",
      //   duration: 1.5,
      // });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <Container ref={containerRef}>
      <div className="avatars" />
      {/* <img className="avatar-1" src={avatar1} alt="" />
      <img className="avatar-2" src={avatar2} alt="" />
      <img className="avatar-3" src={avatar3} alt="" />
      <img className="avatar-4" src={avatar4} alt="" />
      <img className="avatar-5" src={avatar5} alt="" />
      <img className="avatar-6" src={avatar6} alt="" />
      <img className="avatar-7" src={avatar7} alt="" />
      <img className="avatar-8" src={avatar8} alt="" /> */}
    </Container>
  )
}

export default Avatars
