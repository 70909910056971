import { gsap, ScrollTrigger } from "gsap/all";

import HomeSection from "../sections/HomeSection";

const Home = () => {
  gsap.registerPlugin(ScrollTrigger);

  return (
    <>
      <HomeSection />
    </>
  );
};

export default Home;
