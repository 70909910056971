import styled from "styled-components";

import SinglePageHeader from "../components/SinglePageHeader";
import FooterSection from "../sections/FooterSection";

const Section = styled.section`
  min-height: 100vh;
  padding-top: calc(60px + 95px);
  padding-bottom: 105px;
  background-color: #fff;
`;
const Container = styled.div`
  color: #101820;
  width: 95%;
  max-width: 705px;
  margin: 0 auto;
`;
const Title = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  margin: 0;
  span {
    &.light {
      font-weight: 300;
      letter-spacing: -1.5%;
    }
    &.orange {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 30px;
    line-height: 37px;
  }
`;
const Intro = styled.h2`
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  margin: 8px 0 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 18px;
    line-height: 22px;
  }
`;
const Content = styled.div`
  font-size: 18px;
  line-height: 26px;
  a {
    color: inherit;
    font-weight: 700;
    text-decoration: underline;
    &.primaryLink {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 30px;
  }
  span {
    &.bold {
      font-weight: 700;
    }
    &.italic {
      font-style: italic;
      opacity: 0.8;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const DownloadButtonHolder = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;
const DownloadButton = styled.div`
  color: #fff !important;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  width: fit-content;
  padding: 32px 65px;
  display: block;
  background: linear-gradient(180deg, #fb782f 0%, #f88d2b 23.44%, #ff5c35 100%);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  cursor: pointer;
`;

const PressRelease = () => {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(
      "https://foundersworkshop.com/wp-content/uploads/mw-press-release.pdf"
    ).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        console.log("fileURL", fileURL);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "mw-press-release.pdf";
        alink.click();
      });
    });
  };
  return (
    <>
      <SinglePageHeader disableActiveItem activeSmallHeader />
      <Section>
        <Container>
          <Title>
            Founders Workshop <span className="light">Launches New</span>{" "}
            <span className="orange">Metaverse Product Division</span>
          </Title>
          <Intro>
            Phoenix-based software firm will deliver business consulting and
            product development for Metaverse, Web3, and augmented and virtual
            reality solutions.
          </Intro>
          <Content>
            <p>
              PHOENIX, AZ --{" "}
              <a
                href="https://foundersworkshop.com/"
                target="_blank"
                rel="noreferrer"
              >
                Founders Workshop
              </a>
              , a 14-year old digital product and software development firm in
              Phoenix, has announced the formation of its new{" "}
              <span className="bold">Metaverse Workshop</span> division and the
              hiring of Julian Reyes as Chief Metaverse Officer.
            </p>
            <p>
              <a
                href="https://metaverseworkshop.biz/page"
                target="_blank"
                rel="noreferrer"
              >
                Metaverse Workshop
              </a>{" "}
              will deliver business consulting and product development around a
              variety of emerging technologies related to the Metaverse, Web3,
              and augmented and virtual reality.
            </p>
            <p>
              The division’s core offerings will include education and ideation
              workshops, product strategy and development, and ongoing product
              support.
            </p>
            <p>
              <span className="italic">
                “Businesses are looking for an entrypoint to the Metaverse, but
                they don’t always know where to begin,“ Founders Workshop CEO
                Vincent Serpico said. “We want to help them build the business
                case for participation and then develop the products that serve
                as their onramp to the Metaverse.”
              </span>
            </p>
            <p>
              In his role as Chief Metaverse Officer, Reyes will be responsible
              for applying Founders Workshop’s proven product development
              process to the implementation of Metaverse, Web3, and augmented
              and virtual reality solutions.
            </p>
            <p>
              Prior to joining <span className="bold">Founders Workshop</span>,
              Reyes was the CEO and founder of{" "}
              <span className="bold">Keyframe Entertainment</span>, which
              produced media projects and events, built 3D worlds in various
              immersive platforms, and served as executive producer for Fifer
              Garbesi’s virtual 360° experience of Italy’s Damanhur.
            </p>
            <p>
              Reyes has also held positions at Meta, Cisco Systems, Jacobs
              Associates, and Exponent. He received training from the Oculus
              Launch Pad and Oculus Start programs and the EveryRealm Metaverse
              Academy.
            </p>
            <p>
              <span className="italic">
                "I’ve been tracking the evolution of the Metaverse for many
                years and its time has come,” Reyes said. “Founders Workshop's
                proven development approach and successful track record will
                help our clients achieve their goals in the Metaverse."
              </span>
            </p>
            <p>
              <span className="bold">About Founders Workshop.</span>
            </p>
            <p>
              About Founders Workshop. Since 2008, 150+ innovative business
              leaders have trusted{" "}
              <span className="bold">Founders Workshop</span> to build their
              digital products, web and mobile apps, and market-ready startup
              MVPs. From business strategy and consulting services, to product
              design and management to engineering and beyond,{" "}
              <span className="bold">Founders Workshop</span> develops software
              that propels business forward. Learn more at
              www.foundersworkshop.com.
            </p>
            <p>
              <span className="bold">Learn more at</span>{" "}
              <a
                className="primaryLink"
                href="https://foundersworkshop.com/"
                target="_blank"
                rel="noreferrer"
              >
                www.foundersworkshop.com
              </a>
              .
            </p>
            <DownloadButtonHolder onClick={onButtonClick}>
              <DownloadButton>Download PDF Press Release</DownloadButton>
            </DownloadButtonHolder>
          </Content>
        </Container>
      </Section>
      <FooterSection />
    </>
  );
};

export default PressRelease;
