import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap/all";
import styled from "styled-components"

import art1 from "../images/projects/art1.png"
import art2 from "../images/projects/art2.png"
import art3 from "../images/projects/art3.png"
import art4 from "../images/projects/art4.png"
import art5 from "../images/projects/art5.png"
import shapes3 from "../images/projects/shapes3.png"
import fwicon from "../images/projects/fwicon.png"
import triangle from "../images/projects/triangle.png"
import circle from "../images/projects/circle.png"

const Section = styled.section`
  height: 1400px;
  position: relative;
  background: linear-gradient(68.31deg, #4A7EE2 35.77%, #2CC1D8 98.63%);
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 820px;
  }  
`
const Box = styled.div`
  top: 337px;
  left: 170px;
  color: #fff;
  width: 90%;
  max-width: 617px;
  padding: 60px;
  position: absolute;
  border-radius: 12px;
  background-color: rgba(26, 24, 105, 0.57);
  z-index: 1;
  h2 {
    font-size: 40px;
    font-weight: 300;
    line-height: 42px;
    text-transform: uppercase;
    margin: 0 0 25px;
    span {
      font-weight: 700;
    }
  }
  p {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    top: 248px;
    left: 0;
    right: 0;
    padding: 48px 16px;
    margin: 0 auto;
    h2 {
      font-size: 24px;
      line-height: 24px;
      br {
        display: none;
      }
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`
const ShapeImage = styled.img`
  position: absolute;
  &.shape {
    &-1 {
      top: -95px;
      left: 193px;
    }
    &-2 {
      top: -65px;
      right: 150px;
      z-index: 2;
    }
    &-3 {
      left: 153px;
      bottom: 155px;
      z-index: 2;
    }
    &-4 {
      right: 150px;
      bottom: 405px;
      z-index: 2;
    }
    &-5 {
      left: 690px;
      bottom: -40px;
    }
    &-6 {
      top: 135px;
      left: 43px;
      z-index: 2;
    }
    &-7 {
      right: 130px;
      bottom: 230px;
      transform: rotate(75deg);
      z-index: 2;
    }
    &-8 {
      left: 35px;
      bottom: 513px;
    }
    &-9 {
      left: 350px;
      bottom: 30px;
    }
    &-10 {
      left: 760px;
      bottom: 300px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    &.shape {
      &-1 {
        width: 124px;
        height: 124px;
        top: 56px;
        left: 18px;
      }
      &-2 {
        width: 175px;
        height: 177px;
        top: 72px;
        right: 15px;
      }
      &-3 {
        width: 140px;
        height: 140px;
        left: 68px;
        bottom: 117px;
      }
      &-4 {
        width: 99px;
        height: 99px;
        right: 22px;
        bottom: 176px;
      }
      &-5 {
        width: 78px;
        height: 78px;
        left: auto;
        right: 80px;
        bottom: 55px;
      }
      &-6 {
        width: 137px;
        height: 133px;
        top: 137px;
        left: 30px;
      }
      &-7 {
        width: 94px;
        height: 91px;
        top: auto;
        left: 167px;
        bottom: 244px;
      }
      &-8 {
        display: none;
      }
      &-9 {
        width: 68px;
        height: 68px;
        left: 103px;
        bottom: 65px;
      }
      &-10 {
        width: 68px;
        height: 68px;
        left: auto;
        right: 88px;
        bottom: 150px;
      }
    }
  }
`

const ProjectsAI = () => {
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      
      gsap.set(".sectionProjects", { yPercent: 5 });
      gsap.to(".sectionProjects", {
        yPercent: -5,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionProjects",
          end: "bottom center",
          scrub: 1,
        },
      });

      gsap.set(".shape-1", { yPercent: -5 });
      gsap.to(".shape-1", {
        yPercent: 5,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionProjects",
          scrub: 1,
        },
      });
      gsap.set(".shape-2", { yPercent: -5 });
      gsap.to(".shape-2", {
        yPercent: 5,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionProjects",
          scrub: 1,
        },
      });
      gsap.set(".shape-3", { yPercent: -5 });
      gsap.to(".shape-3", {
        yPercent: 5,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionProjects",
          scrub: 1,
        },
      });
      gsap.set(".shape-4", { yPercent: -2 });
      gsap.to(".shape-4", {
        yPercent: 1,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionProjects",
          scrub: 1,
        },
      });
      gsap.set(".shape-5", { yPercent: -8 });
      gsap.to(".shape-5", {
        yPercent: 4,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionProjects",
          scrub: 1,
        },
      });
    
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <Section ref={containerRef}>
      <Box className="sectionProjects">
        <h2>
          <span>Metaverse Workshop</span> <br />
          utilizes AI generated <br />
          ART for concepting and <br />
          mocking prototypes.
        </h2>
        <p>
          The ability to quickly iterate and utilize AI tools such as <br />
          MidJourney and Dalle-2 sets us apart from other <br />
          companies.
        </p>
      </Box>
      <ShapeImage src={art1} className="shape-1" />
      <ShapeImage src={art2} className="shape-2" />
      <ShapeImage src={art3} className="shape-3" />
      <ShapeImage src={art4} className="shape-4" />
      <ShapeImage src={art5} className="shape-5" />
      <ShapeImage src={shapes3} className="shape-6" />
      <ShapeImage src={shapes3} className="shape-7" />
      <ShapeImage src={fwicon} className="shape-8" />
      <ShapeImage src={triangle} className="shape-9" />
      <ShapeImage src={circle} className="shape-10" />
    </Section>
  )
}

export default ProjectsAI
