import { useMemo } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

import linkedInIcon from "../Icons/linkedin.svg";
import facebookIcon from "../Icons/facebook.svg";
import msfIcon from "../Icons/MSF.png";
import logoMetaverseWorkshop from "../images/header/MetaverseWorkshopLogo.svg";
import labelMetaverseWorkshop from "../images/header/MetaverseLabel.svg";
import plainLogo from "../images/footer/plainLogo.svg";
import nvidiaCommunity from "../images/footer/nvidiaCommunity.png";

const Footer = styled.footer`
  position: relative;
  z-index: 1;
`;
const Content = styled.div`
  /* padding: 35px 90px; */
  padding: 35px 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(23.29deg, #042359 15.04%, #011234 84.96%);
  mix-blend-mode: normal;
  box-shadow: 0px -35px 66px rgba(5, 5, 24, 0.46);

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 24px 16px;
    flex-direction: column;
  }
`;
const LeftContent = styled.div`
  padding-right: 65px;
  border-right: 1px solid #1a428a;
  .logoWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .text {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 35px;
    margin-bottom: 0;
    span {
      font-weight: 700;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-right: 0;
    padding-bottom: 24px;
    border-right: none;
    border-bottom: 1px solid #1a428a;
    .logoWrapper {
      justify-content: center;
    }
    .text {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-top: 24px;
      br {
        display: none;
      }
    }
  }
`;
const RightContent = styled.div`
  padding-top: 10px;
  padding-left: 65px;
  .redirectsWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .navigation {
    display: flex;
    margin-top: 45px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      li {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        flex: 1 0 100%;
        &.active {
          a {
            color: ${(props) => props.theme.colors.primary};
          }
        }
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    text-align: center;
    padding: 24px 0 0;
    .navigation {
      padding-left: 25px;
      margin-top: 24px;
      ul li {
        text-align: left;
      }
    }
  }
`;
const Copyright = styled.div`
  width: 100%;
  height: 120px;
  background-color: #03173c;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
    span {
      font-weight: 700;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: fit-content;
    padding: 32px 35px;
    p {
      line-height: 18px;
      text-align: center;
    }
  }
`;

const FooterSection = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <Footer>
      <Content>
        <LeftContent>
          <div className="logoWrapper">
            <img
              className="logo"
              src={logoMetaverseWorkshop}
              alt="Founders Workshop Metaverse Logo"
            />
            <img
              className="label"
              src={labelMetaverseWorkshop}
              alt="Metaverse Workshop"
            />
          </div>
          <p className="text">
            Metaverse Workshop helps SMB’s get into the Metaverse. Utilizing a{" "}
            <br />
            business-centric-first approach, we provide a series of workshops{" "}
            <br />
            to help your team educate and ideate Metaverse solutions. We then
            partner <br />
            with the best Metaverse world builders to help you build the right{" "}
            <br />
            Metaverse experience for your business. Once your Metaverse world{" "}
            <br />
            is built, we provide ongoing development and business support.{" "}
            <br />
            As entrepreneurs and business owners, our philosophy is <br />
            “the business drives the technology”, not the other way around.
          </p>
        </LeftContent>
        <RightContent>
          <div className="redirectsWrapper">
            <a
              href="https://foundersworkshop.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={plainLogo} alt="Founders Workshop" />
            </a>
            <a
              href="https://www.linkedin.com/company/foundersworkshop/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedInIcon} alt="Founders Workshop LinkedIn" />
            </a>
            <a
              href="https://www.facebook.com/MetaverseWorkshop/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebookIcon} alt="Founders Workshop Facebook" />
            </a>

            <img
              src={msfIcon}
              alt="Metaverse Standards Forum"
              style={{ height: "37px" }}
            />
          </div>
          <nav className="navigation">
            <div style={{width: "162px"}}>
              <ul >
                <li>
                  <HashLink smooth to="/page#home">
                    Home
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/page#partnership">
                    Partnerships
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/page#process">
                    Process
                  </HashLink>
                </li>
                <li>
                  <Link smooth to="/projects">
                    Projects
                  </Link>
                </li>
                <li>
                  <HashLink smooth to="/page#teamwork">
                    Teamwork
                  </HashLink>
                </li>
                <li className="active">
                  <HashLink smooth to="/page#contact">
                    Contact
                  </HashLink>
                </li>
                <li>
                  <Link
                    to="/PressRelease"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Press Release
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <img src={nvidiaCommunity} alt="Nvidia" />
            </div>
          </nav>
        </RightContent>
      </Content>
      <Copyright>
        <p>
          Copyright &copy; 2022 - {currentYear} -{" "}
          <span>Metaverse Workshop</span> - All right reserved
        </p>
      </Copyright>
    </Footer>
  );
};

export default FooterSection;
