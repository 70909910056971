import styled from "styled-components"

import figure1 from "../images/figures/figure1.png"
import figure2 from "../images/figures/figure2.svg"
import figure3 from "../images/figures/figure3.png"
import figure4 from "../images/figures/figure4.png"
import figure5 from "../images/figures/figure5.png"
import figure6 from "../images/figures/figure6.svg"
import figure7 from "../images/figures/figure7.svg"
import figure8 from "../images/figures/figure8.svg"
// import figure9 from "../images/figures/figure9.png"
import figure10 from "../images/figures/figure10.svg"
import figure11 from "../images/figures/figure11.svg"
import figure12 from "../images/figures/figure12.svg"
import figure13 from "../images/figures/figure13.svg"
import figure14 from "../images/figures/figure14.svg"
import figure15 from "../images/figures/figure15.svg"
import figuresMobile from "../images/figures/home-figures-mobile.png"

const Container = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  user-select: none;
  pointer-events: none;
  img {
    position: absolute;
    &.figure {
      &-1 {
        top: 14px;
        left: 13px;
      }
      &-2 {
        top: 317px;
        left: 0;
      }
      &-3 {
        top: 0;
        right: 0;
      }
      &-4 {
        top: 480px;
        right: 0;
      }
      &-5 {
        left: 202px;
        bottom: -85px;
      }
      &-6 {
        top: 80px;
        left: 161px;
      }
      &-7 {
        top: 110px;
        left: 203px
      }
      &-8 {
        top: 202px;
        left: 233px
      }
      &-9 {
        top: 181px;
        left: 433px;
        width: 18px;
      }
      &-10 {
        top: 536px;
        left: 65px;
      }
      &-11 {
        top: 577px;
        left: 137px;
      }
      &-12 {
        top: 637px;
        left: 186px;
      }
      &-13 {
        left: 532px;
        bottom: 14px;
        width: 100px;
      }
      &-14 {
        left: 654px;
        bottom: 82px;
        transform: rotate(-70deg);
      }
      &-15 {
        left: 694px;
        bottom: 107px;
        transform: rotate(-70deg);
      }
      &-16 {
        top: 117px;
        right: 242px;
      }
      &-17 {
        top: 271px;
        right: 260px;
        height: 40px;
        transform: rotate(45deg);
      }
      &-18 {
        top: 319px;
        right: 167px;
        transform: rotate(-70deg);
      }
      &-19 {
        top: 286px;
        right: 120px;
        transform: rotate(-70deg);
      }
      &-20 {
        top: 360px;
        right: 116px;
      }
      &-21 {
        top: 640px;
        right: 74px;
      }
      &-mobile {
        display: none;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    img {
      &[class^="figure-"] {
        display: none;
      }
      &.figure-mobile {
        width: 100%;
        display: block;
      }
    }
  }
`
const InnerLayer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`

const HomeSectionParallaxLayer = () => {
  return (
    <Container>
      <InnerLayer className="frontLayer" style={{ zIndex: 1 }}>
        <img className="figure-1" src={figure1} alt="" />
        <img className="figure-2" src={figure2} alt="" />
        <img className="figure-3" src={figure3} alt="" />
        <img className="figure-4" src={figure4} alt="" />
        <img className="figure-5" src={figure5} alt="" />
      </InnerLayer>
      <InnerLayer className="backLayer">
        <img className="figure-6" src={figure6} alt="" />
        <img className="figure-7" src={figure7} alt="" />
        <img className="figure-8" src={figure8} alt="" />
        <img className="figure-9" src={figure7} alt="" />
        <img className="figure-10" src={figure10} alt="" />
        <img className="figure-11" src={figure11} alt="" />
        <img className="figure-12" src={figure12} alt="" />
        <img className="figure-13" src={figure11} alt="" />
        <img className="figure-14" src={figure6} alt="" />
        <img className="figure-15" src={figure7} alt="" />
        <img className="figure-16" src={figure13} alt="" />
        <img className="figure-17" src={figure8} alt="" />
        <img className="figure-18" src={figure6} alt="" />
        <img className="figure-19" src={figure7} alt="" />
        <img className="figure-20" src={figure14} alt="" />
        <img className="figure-21" src={figure15} alt="" />
      </InnerLayer>
      <img className="figure-mobile" src={figuresMobile} alt="" />
    </Container>
  )
}

export default HomeSectionParallaxLayer
