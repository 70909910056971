import { useContext } from "react";
import { useState, useEffect, useCallback } from "react";

import { NavigationContext } from "../App";

const FIXED_HEADER_OFFSET = 310;

const useScroll = () => {
  const [isFixedHeader, setIsFixedHeader] = useState(false);
  const [activeNavItem, setActiveNavItem] = useContext(NavigationContext)

  useEffect(() => {
    window.addEventListener('scroll', handleFixedHeader, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleFixedHeader);
    }
  }, []);

  const handleFixedHeader = useCallback(() => {
    const isFixed = window.pageYOffset >= FIXED_HEADER_OFFSET;
    setIsFixedHeader(isFixed);
  }, [])

  const handleVisibleSection = useCallback((inView, entry) => {
    const { id } = entry.target
    if (inView) setActiveNavItem(id)
  }, [])

  return { isFixedHeader, handleVisibleSection };
}

export default useScroll;
