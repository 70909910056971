import { BREAKPOINTS } from "./constants"

const colors = {
  primary: "#FF5C35",
  pink: "#E797F3",
  lightBlue: "#4ECCE0",
}

const breakpoints = {
  xl: BREAKPOINTS.XL,
  lg: BREAKPOINTS.LG,
  md: BREAKPOINTS.MD,
  sm: BREAKPOINTS.SM,
  xs: BREAKPOINTS.XS,
}

const theme = {
  colors,
  breakpoints,
}

export { theme }