import { useState, createContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import SinglePage from "./pages/SinglePage";
import Projects from "./pages/Projects";
import PressRelease from "./pages/PressRelease";
import GlobalStyles from "./components/GlobalStyles";

import { theme } from "./utils/theme";

// import "./App.css";

export const NavigationContext = createContext([])

const App = () => {
  const [activeNavItem, setActiveNavItem] = useState("home")

  return (
    <NavigationContext.Provider value={[activeNavItem, setActiveNavItem]}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/page" element={<SinglePage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/PressRelease" element={<PressRelease />} />
            <Route path="*" element={ <Navigate to="/page" replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </NavigationContext.Provider>
  );
}

export default App;
