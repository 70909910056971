import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap/all";
import styled from "styled-components";

import AngleDown from "../components/commons/icons/AngleDown";
import { SectionTitleBox, DesktopView, MobileView } from "../components/commons/ui";

import { getContextDelay } from "../helpers/animationHelper";

import logoMetaverseWorkshop from "../images/header/MetaverseWorkshopLogo.svg";
import labelMetaverseWorkshop from "../images/header/MetaverseLabelCentered.svg";
import mouseIcon from "../images/icons/mouse.svg";
import shapes from "../images/projects/shapes1.png";
import shapesMobile from "../images/projects/shapesMobile.png";

const Section = styled.section`
  height: 850px;
  padding-top: 120px;
  position: relative;
  overflow-y: hidden;
  background: radial-gradient(
      59.41% 92.15% at 87.81% 7.77%,
      #6c088e 0%,
      rgba(11, 1, 52, 0.6) 100%
    ),
    radial-gradient(
      80.66% 83.61% at 5.78% 10.53%,
      #0fa5d5 0%,
      rgba(1, 52, 12, 0.4) 100%
    ),
    radial-gradient(54.84% 85.69% at 37.73% 77.03%, #083d8e 0%, #011234 100%);
  mix-blend-mode: normal;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: fit-content;
    padding: 93px 0;
  }
`;
const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;
const Logo = styled.div`
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  .logo {
    width: 105px;
  }
  .label {
    width: 165px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;
const Container = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  & > * {
    flex: 1;
  }
  .titleBox {
    h3 {
      color: ${(props) => props.theme.colors.primary};
    }
    h4 {
      font-size: 48px;
      line-height: 47px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    .titleBox {
      h4 {
        font-size: 32px;
        line-height: 32px;
        max-width: 325px;
        br {
          display: none;
        }
      }
    }
  }
`;
const Text = styled.div`
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  padding-top: 40px;
  span {
    font-weight: 700;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 18px;
    padding-top: 16px;
  }
`;
const ScrollDown = styled.div`
  margin-top: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  svg {
    animation: scroll 0.95s ease-in-out alternate infinite;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;
const Shapes = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  position: absolute;
`;

const ProjectsBannerSection = () => {
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      getContextDelay(0.5).from(".logo", {
        y: 50,
        opacity: 0,
        ease: "power2",
        duration: 1.5,
      });

      getContextDelay(0.8).from(".label", {
        y: 50,
        opacity: 0,
        ease: "power",
        duration: 1.5,
      });

      getContextDelay(1).from(".titleProjects", {
        y: 50,
        opacity: 0,
        ease: "power",
        duration: 1.5,
      });
      getContextDelay(1).from(".titleDescription", {
        y: 50,
        opacity: 0,
        ease: "power",
        duration: 1.5,
      });
      getContextDelay(2).from(".scrollIconProject", {
        y: 50,
        opacity: 0,
        ease: "power",
        duration: 1.5,
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);
  return (
    <Section ref={containerRef}>
      <SectionContent>
        <Logo>
          <img
            className="logo"
            src={logoMetaverseWorkshop}
            alt="Founders Workshop Metaverse Logo"
          />
          <img
            className="label"
            src={labelMetaverseWorkshop}
            alt="Metaverse Workshop"
          />
        </Logo>
        <Container>
          <SectionTitleBox className="titleBox">
            <h3>Projects</h3>
            <h4 className="titleProjects">
              Our team can <br />
              help you explore <br />
              your options in <br />
              the metaverse, <br />
              Web3, and XR.
            </h4>
          </SectionTitleBox>
          <Text className="titleDescription">
            We leverage a talented bench of designers and <br />
            developers to bring your product or brand to life in the <br />
            digital realm. From branded experiences in virtual <br />
            worlds to augmented & virtual reality solutions for <br />
            internal collaboration,{" "}
            <span>
              Metaverse Workshop builds <br />
              your onramp to the Metaverse.
            </span>{" "}
            <br />
            <br />
            Here are some samples of our 3D world builds and <br />
            platforms covered:
          </Text>
        </Container>
        <ScrollDown className="scrollIconProject">
          <img src={mouseIcon} alt="" />
          <AngleDown />
        </ScrollDown>
      </SectionContent>
      <DesktopView>
        <Shapes src={shapes} />
      </DesktopView>
      <MobileView>
        <Shapes src={shapesMobile} />
      </MobileView>
    </Section>
  );
};

export default ProjectsBannerSection;
