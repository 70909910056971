import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap/all";
import styled from "styled-components";

import slide1 from "../images/projects/slide1.png";
import slide2 from "../images/projects/slide2.png";
import slide3 from "../images/projects/slide3.png";
import slide4 from "../images/projects/slide4.png";
import slide5 from "../images/projects/slide5.png";
import slide6 from "../images/projects/slide6.png";
import slide7 from "../images/projects/slide7.png";
import shapes2 from "../images/projects/shapes2.png";

const Section = styled.section`
  min-height: 4200px;
  padding: 110px 0;
  position: relative;
  background: linear-gradient(240.37deg, #8d229e 13.39%, #5e37cd 85.73%);

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    min-height: 2245px;
    padding: 95px 0 60px;
  }
`;
const Slide = styled.div`
  width: 90%;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  &.slide {
    &-1 {
      z-index: 2;
    }
    &-2 {
      transform: translateY(50px);
    }
    &-3 {
      transform: translateY(90px);
      z-index: 2;
    }
    &-4 {
      transform: translateY(140px);
    }
    &-5 {
      transform: translateY(180px);
      z-index: 2;
    }
    &-6 {
      transform: translateY(228px);
    }
    &-7 {
      transform: translateY(268px);
    }
  }
  .banner3d {
    width: 100%;
    perspective: 700px;
    -webkit-transform-style: flat; /* fix bug with safari */
    transform-style: preserve-3d;
    &-front {
      transform-style: preserve-3d;
      &:after {
        content: "";
        left: 0;
        bottom: -80px;
        width: 100%;
        height: 80px;
        position: absolute;
        background-color: #bfbfbf;
        transform-origin: center top;
      }
    }
    &-1 {
      &-front {
        &:after {
          transform: rotateX(-60deg);
          background: linear-gradient(180deg, #1f6081 0%, #3a81b0 100%);
        }
      }
      .text {
        background: linear-gradient(68.31deg, #4a7ee2 35.77%, #2cc1d8 98.63%);
      }
    }
    &-2 {
      &-front {
        transform: translateZ(-68px);
        &:after {
          transform: rotateX(60deg);
          background: linear-gradient(180deg, #3a81b0 0%, #1f6081 100%);
        }
      }
      .text {
        background: linear-gradient(243.25deg, #235cc1 7.36%, #0d306d 66.75%);
      }
    }
    &-3 {
      &-front {
        &:after {
          transform: rotateX(-60deg);
          background: linear-gradient(180deg, #a64523 0%, #bb5231 100%);
        }
      }
      .text {
        background: linear-gradient(246.84deg, #f88d2b -0.72%, #ff5c35 64.98%);
      }
    }
    &-4 {
      &-front {
        transform: translateZ(-68px);
        &:after {
          transform: rotateX(60deg);
          background: linear-gradient(180deg, #3a81b0 0%, #1f6081 100%);
        }
      }
      .text {
        background: linear-gradient(180deg, #3a81b0 0%, #1f6081 100%);
      }
    }
    &-5 {
      &-front {
        &:after {
          transform: rotateX(-60deg);
          background: linear-gradient(180deg, #1f6081 0%, #3a81b0 100%);
        }
      }
      .text {
        background: linear-gradient(68.31deg, #4a7ee2 35.77%, #2cc1d8 98.63%);
      }
    }
    &-6 {
      &-front {
        transform: translateZ(-68px);
        &:after {
          transform: rotateX(60deg);
          background: linear-gradient(180deg, #df7250 0%, #a64523 100%);
        }
      }
      .text {
        background: linear-gradient(243.25deg, #264d94 7.36%, #0d306d 66.75%);
      }
    }
    &-7 {
      &-front {
        &:after {
          display: none;
        }
      }
      .text {
        background: linear-gradient(246.84deg, #f88d2b -0.72%, #ff5c35 64.98%);
      }
    }
  }
  .imageHolder {
    height: 360px;
    background-color: #ccc;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text {
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    padding: 38px 60px;
    height: 165px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
    }
    span {
      font-weight: 700;
    }
    a {
      color: #fff;
    }
    .title {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 15px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    .imageHolder {
      height: 113px;
    }
    .text {
      font-size: 14px;
      line-height: 18px;
      height: auto;
      padding: 18px 12px;
      br {
        display: none;
      }
      .title {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;
const Shapes = styled.img`
  top: -100px;
  left: 17px;
  position: absolute;
`;

const ProjectsSlides = () => {
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const slides = document.querySelectorAll(".slide");

      slides.forEach((slide, index) => {
        const banner3D = slide.querySelector(".banner3d");

        gsap.set(banner3D, { perspectiveOrigin: "center -100vh" });

        gsap.to(banner3D, {
          scrollTrigger: {
            trigger: banner3D,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
          perspectiveOrigin: "center 100vh",
          ease: "none",
        });
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <Section ref={containerRef}>
      <div>
        <Slide className="slide slide-1">
          <div className="banner3d banner3d-1">
            <div className="banner3d-front banner3d-1-front">
              <div className="imageHolder">
                <img src={slide1} alt="" />
              </div>
              <div className="text">
                <p>
                  Metaverse Workshop and partners are experts in developing
                  immersive XR experiences for brands across all industries. Our
                  business-centric solutions range from Healthcare applications
                  and product visualizations to multiplayer business solutions
                  that will enhance your digital brand presence.{" "}
                  <i>Image by 3lb XR</i>.
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <Slide className="slide slide-2">
          <div className="banner3d banner3d-2">
            <div className="banner3d-front banner3d-2-front">
              <div className="imageHolder">
                <img src={slide2} alt="" />
              </div>
              <div className="text">
                <p>
                  Cross-Compatibility: With OpenGL based game and VR platforms
                  allowing cross-compatibility and our <br />
                  partnerships with Luxx Studios, we are able to generate for
                  you cross-platform builds… to experience your <br />
                  brand on various platforms.
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <Slide className="slide slide-3">
          <div className="banner3d banner3d-3">
            <div className="banner3d-front banner3d-3-front">
              <div className="imageHolder">
                <img src={slide3} alt="" />
              </div>
              <div className="text">
                <p>
                  We have built worlds in <span>Breakroom</span>, which is a
                  virtual world platform for developing & publishing multi-user
                  games, apps, and experiences including MMOs. Our team and
                  extended Partner network has also built multiple worlds in{" "}
                  <span>Spatial.io</span>, which range from network
                  environments, event product launches, & Digital Twins with
                  functional NFT's.
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <Slide className="slide slide-4">
          <div className="banner3d banner3d-4">
            <div className="banner3d-front banner3d-4-front">
              <div className="imageHolder">
                <img src={slide4} alt="" />
              </div>
              <div className="text">
                <p>
                  Julian Reyes, our Chief Metaverse Officer (CMVO) has built
                  structures in <span>Decentraland</span> and our team is <br />
                  versed in creating on multiple platforms. Decentraland is a
                  browser-based 3D virtual world. The platform <br />
                  allows users to work with NFTs via the MANA cryptocurrency
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <Slide className="slide slide-5">
          <div className="banner3d banner3d-5">
            <div className="banner3d-front banner3d-5-front">
              <div className="imageHolder">
                <img src={slide5} alt="" />
              </div>
              <div className="text">
                <p>
                  Our designers have built 3D worlds in various immersive
                  platforms. Our solutions allow brands and businesses to create
                  virtual events, product launches,and increase brand equity.{" "}
                  <i>Image by Cause and Christ</i>.
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <Slide className="slide slide-6">
          <div className="banner3d banner3d-6">
            <div className="banner3d-front banner3d-6-front">
              <div className="imageHolder">
                <img src={slide6} alt="" />
              </div>
              <div className="text">
                <p>
                  We are prolific builders in Meta Horizon Worlds and are
                  currently working on Digital Twins for real life <br />
                  businesses on the platform. Horizon Worlds allows you to host
                  events, games, and social activities. While <br />
                  Horizon Workrooms facilitates meetings. Please ask us for a
                  demo.
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <Slide className="slide slide-7">
          <div className="banner3d banner3d-7">
            <div className="banner3d-front banner3d-7-front">
              <div className="imageHolder">
                <img src={slide7} alt="" />
              </div>
              <div className="text">
                <p className="title">
                  Metaverse Workshop is currently working on a Digital Twin of{" "}
                  <a
                    href="https://www.breakthroughsmashroom.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Breakthrough Smash Room
                  </a>
                  .
                </p>
                <p>
                  Metaverse Workshop can bring small and medium sized retail
                  stores into the Metaverse at a cost-effective entry point.{" "}
                  <br />
                  Now, traditional brick-and-mortar stores can take full
                  advantage of the powerful marketing reach the Metaverse <br />
                  has to offer, at a fraction of the cost.
                </p>
              </div>
            </div>
          </div>
        </Slide>
      </div>
      <Shapes src={shapes2} />
    </Section>
  );
};

export default ProjectsSlides;
