import { gsap, ScrollTrigger } from "gsap/all"

import SinglePageHeader from "../components/SinglePageHeader"
import ProjectsBannerSection from "../sections/ProjectsBannerSection"
import ProjectsSlides from "../sections/ProjectsSlides"
import ProjectsAI from "../sections/ProjectsAI"
import ContactSection from "../sections/ContactSection"
import FooterSection from "../sections/FooterSection"

const Projects = () => {
  gsap.registerPlugin(ScrollTrigger)

  return (
    <>
      <SinglePageHeader disableActiveItem active="projects" />
      <ProjectsBannerSection />
      <ProjectsSlides />
      <ProjectsAI />
      <ContactSection />
      <FooterSection />
    </>
  )
}

export default Projects
