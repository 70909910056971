import { useState } from "react"
import { Box, TextField as MuiTextField, Button as MuiButton, CircularProgress } from "@mui/material"
import * as yup from "yup"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 0 10px;
  margin: 70px auto 0;
  position: relative;
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 25px;
  }
`
const TextField = styled(MuiTextField)`
  .MuiOutlinedInput-root {
    background-color: #fff;
  }
`
const Button = styled(MuiButton)`
  font-family: "Din", sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  margin-top: 10px !important;
  background-color: ${props => props.theme.colors.primary} !important;
`
const Success = styled.div`
  color: #66ba6a;
  margin-top: 10px;
`

const ContactForm = () => {
  const [form, setForm] = useState({ name: '', email: '' })
  const [formError, setFormError] = useState({ field: '', message: '' })
  const [formSuccess, setFormSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    schema.validate(form)
      .then((value) => {
        setFormError({ field: '', message: '' })
        setLoading(true)
        fetch('https://api.metaverseworkshop.biz/api/sendEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(form),
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false)
            if (data.status === 200) setFormSuccess(true)
            else alert(data.message)
          })
          .catch((error) => {
            setLoading(false)
            alert(error)
          })
      })
      .catch((error) => {
        setLoading(false)
        setFormError({ field: error.path, message: error.message })
      })
  }

  return (
    <Container>
      <Box 
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 0.5 },
        }}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          error={formError.field === 'name'}
          color="primary"
          variant="outlined"
          name="name"
          placeholder="Enter your Name here"
          helperText={formError.field === 'name' ? formError.message : ''}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          error={formError.field === 'email'}
          color="primary"
          variant="outlined"
          name="email"
          placeholder="Enter your Email here"
          helperText={formError.field === 'email' ? formError.message : ''}
          fullWidth
          onChange={handleChange}
        />
        <Button color="primary" variant="contained" type="submit" disabled={loading} fullWidth>
          {loading ? (
            <>
              <CircularProgress color='inherit' size={20} thickness={2.6} /> &nbsp;
              Sending Message...
            </>
          ) : (
            <>Send Message</>
          )}
        </Button>
        {formSuccess && (
          <Success>The message has been sent. Founders Workshop will be in touch soon.</Success>
        )}
      </Box>
    </Container>
  )
}

export default ContactForm
