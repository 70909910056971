import { useState, useContext } from "react";
import { ClickAwayListener } from "@mui/material";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled, { css } from "styled-components";

import { NavigationContext } from "../App";
import useScroll from "../hooks/useScroll";

import logoMetaverseWorkshop from "../images/header/MetaverseWorkshopLogo.svg";
import labelMetaverseWorkshop from "../images/header/MetaverseLabelHorizontal.svg";
import logoMetaverseWorkshopMobile from "../images/header/MetaverseLogoMobile.svg";
import dots from "../images/header/dots.svg";

const Header = styled.header`
  top: 0;
  width: 100%;
  padding: 15px 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  ${({ sticky }) => {
    if (sticky) {
      return css`
        height: 60px;
        padding-left: 20px;
        padding-right: 40px;
        justify-content: space-between;
        position: fixed;
        background: linear-gradient(266.64deg, #083d8e 18.38%, #052a67 73.84%);
        mix-blend-mode: normal;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          0px 4px 26px rgba(7, 15, 26, 0.6);
        animation: fadeInDown 0.3s ease-in;
        z-index: 10;
        .logoContainer {
          display: flex;
        }
      `;
    }
  }}
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 8px 16px;
  }
`;
const Navigation = styled.nav`
  /* padding: 15px 0; */
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    li {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      a {
        color: #fff;
        text-decoration: none;
      }
      &.contact a {
        color: ${(props) => props.theme.colors.primary};
      }
      &.active,
      &:hover {
        a {
          color: #f88d2b;
          transition: color 0.2s ease-in-out;
        }
      }
      &.closeNav {
        display: none;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    /* display: none; */
    top: 0;
    right: 0;
    width: 50vw;
    height: 100vh;
    position: fixed;
    background-color: #040a3c;
    transform: translateX(150%);
    box-shadow: -34px 32px 51px rgba(5, 4, 70, 0.34);
    transition: transform 0.3s ease-in-out;
    ${({ slideIn }) => {
      if (slideIn)
        return css`
          transform: translateX(0);
        `;
    }}
    ul {
      padding: 8px 16px;
      display: flex;
      flex-direction: column;
      li {
        text-align: right;
        width: 100%;
        a {
          display: block;
        }
        &.closeNav {
          display: block;
          button {
            color: #fff;
            font-family: "Din", sans-serif;
            font-size: 45px;
            font-weight: 700;
            text-align: right;
            width: 100%;
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
`;
const Logo = styled.div`
  display: none;
  a {
    display: flex;
    align-items: center;
    gap: 17px;
  }
  .logo {
    width: 55px;
  }
  .label {
    width: 200px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none !important;
  }
`;
const MobileView = styled.div`
  display: none;
  align-items: center;
  button {
    height: fit-content;
    padding: 15px;
    margin-right: -15px;
    border: none;
    background-color: transparent;
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const SinglePageHeader = ({ active, disableActiveItem, activeSmallHeader }) => {
  const [revealNav, setRevealNav] = useState(false);

  const [activeNavItem] = useContext(NavigationContext);

  const { isFixedHeader } = useScroll();

  return (
    <Header sticky={isFixedHeader || activeSmallHeader}>
      <Logo className="logoContainer">
        <HashLink to="/page#home">
          <img
            className="logo"
            src={logoMetaverseWorkshop}
            alt="Founders Workshop Metaverse Logo"
          />
          <img
            className="label"
            src={labelMetaverseWorkshop}
            alt="Metaverse Workshop"
          />
        </HashLink>
      </Logo>
      <MobileView>
        <HashLink to="/page#home">
          <img
            src={logoMetaverseWorkshopMobile}
            alt="Founders Workshop Metaverse Logo"
          />
        </HashLink>
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setRevealNav(true);
          }}
        >
          <img src={dots} alt="" />
        </button>
      </MobileView>
      <ClickAwayListener onClickAway={() => setRevealNav(false)}>
        <Navigation slideIn={revealNav}>
          <ul>
            <li className="closeNav">
              <button type="button" onClick={() => setRevealNav(false)}>
                &times;
              </button>
            </li>
            <li
              className={
                activeNavItem === "home" && !disableActiveItem ? "active" : ""
              }
              onClick={() => setRevealNav(false)}
            >
              <HashLink smooth to="/page#home">
                Home
              </HashLink>
            </li>
            <li
              className={activeNavItem === "process" ? "active" : ""}
              onClick={() => setRevealNav(false)}
            >
              <HashLink smooth to="/page#process">
                Process
              </HashLink>
            </li>
            <li
              className={activeNavItem === "teamwork" ? "active" : ""}
              onClick={() => setRevealNav(false)}
            >
              <HashLink smooth to="/page#teamwork">
                Teamwork
              </HashLink>
            </li>
            <li
              className={activeNavItem === "partnership" ? "active" : ""}
              onClick={() => setRevealNav(false)}
            >
              <HashLink smooth to="/page#partnership">
                Partnerships
              </HashLink>
            </li>
            <li
              className={active === "projects" ? "active" : ""}
              onClick={() => setRevealNav(false)}
            >
              <Link to="/projects">Projects</Link>
            </li>
            <li
              className={activeNavItem === "contact" ? "active" : ""}
              onClick={() => setRevealNav(false)}
            >
              <HashLink smooth to="/page#contact">
                Contact
              </HashLink>
            </li>
          </ul>
        </Navigation>
      </ClickAwayListener>
    </Header>
  );
};

export default SinglePageHeader;
