import styled from "styled-components"

import vector1 from "../../images/teamwork/vector1.svg"
import vector2 from "../../images/teamwork/vector2.svg"

export const Container = styled.div`
  width: 95%;
  max-width: 1100px;
  position: relative;
  margin: 0 auto;
`

export const SectionTitleBox = styled.div`
  text-transform: uppercase;
  h3 {
    color: ${props => props.theme.colors.pink};
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 20px;
  }
  h4 {
    color: #fff;
    font-size: 60px;
    font-weight: 300;
    line-height: 62px;
    letter-spacing: -0.5px;
    margin: 0;
    span {
      color: ${props => props.theme.colors.lightBlue};
      font-weight: 700;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    h3 {
      font-size: 16px;
      line-height: 20px;
    }
    h4 {
      font-size: 32px;
      line-height: 32px;
    }
  }
`

export const BlueBox = styled.div`
  width: 617px;
  padding: 60px;
  border-radius: 12px;
  background: rgba(4, 10, 60, 0.79);
  position: relative;
  z-index: 1;
  h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 300;
    line-height: 42px;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    margin: 0 0 24px;
    span {
      color: ${props => props.theme.colors.primary};
      font-weight: 700;
    }
  }
  .description {
    p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      span {
        font-weight: 700;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    padding: 48px 16px;
    h2 {
      font-size: 24px;
      line-height: 24px;
    }
    .description p {
      font-size: 14px;
      line-height: 18px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    .description br {
      display: none;
    }
  }
`

export const TeamworkSlide = styled.div`
  color: #e8e8e8;
  margin: 0 auto;
  position: relative;
  background-color: transparent;
  line-height: 1em;
  &.slide {
    &-3 {
      transform: translateY(120px);
    }
    &-4 {
      transform: translateY(173px);
    }
    &-5 {
      transform: translateY(210px);
    }
  }
  .banner3d {
    width: 100%;
    perspective: 700px;
    -webkit-transform-style: flat;
    transform-style: preserve-3d;
    &-front {
      height: 320px;
      display: flex;
      background-color: #222;
      transform-style: preserve-3d;
      &:after {
        content: "";
        left: 0;
        bottom: -80px;
        width: 100%;
        height: 80px;
        position: absolute;
        background-color: #bfbfbf;
        transform-origin: center top;
      }
    }
    &-1 {
      &-front {
        /* transform: translateZ(50px); */
        background: linear-gradient(68.31deg, #4A7EE2 35.77%, #2CC1D8 98.63%);
        &:after {
          transform: rotateX(-60deg);
          background: linear-gradient(180deg, #3170BB 0%, #033774 100%);
        }
        .personWrapper {
          flex: 0 0 444px;
          height: 403px;
          transform: translateY(-83px);
          .photo:before {
            right: -24px;
            width: 24px;
            background-image: url(${vector1});
          }
        }
      }
    }
    &-2 {
      transform: translateY(50px);
      &-front {
        transform: translateZ(-60px);
        background: linear-gradient(243.25deg, #235CC1 7.36%, #0D306D 66.75%);
        &:after {
          transform: rotateX(-60deg);
          background: linear-gradient(180deg, #033774 0%, #3170BB 130.77%);
        }
        .personWrapper {
          flex: 0 0 427px;
          height: 363px;
          transform: translateY(-34px);
          .photo:before {
            left: -71px;
            width: 72px;
            background-image: url(${vector2});
          }
        }
      }
    }
    &-3 {
      &-front {
        transform: translateZ(-130px);
        background: linear-gradient(243.25deg, #264D94 7.36%, #0D306D 66.75%);
        &:after {
          transform: rotateX(60deg);
          background: linear-gradient(180deg, #033774 0%, #3170BB 130.77%);
        }
        .personWrapper {
          flex: 0 0 363px;
          height: 320px;
        }
      }
    }
    &-4 {
      &-front {
        transform: translateZ(-60px);
        &:after {
          transform: rotateX(60deg);
          background: linear-gradient(180deg, #3170BB 0%, #033774 100%);
        }
      }
    }
    &-5 {
      &-front {
        transform: translateZ(10px);
        &:after {
          display: none;
        }
      }
    }
    .personWrapper {
      .photo {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &:before {
          content: "";
          height: 100%;
          display: block;
          position: absolute;
        }
      }
    }
    .personBio {
      flex: 1;
      padding: 40px 70px;
      p {
        margin: 0;
        &.name {
          font-size: 24px;
          font-weight: 700;
          line-height: 33px;
          letter-spacing: -0.015em;
        }
        &.position {
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          text-transform: uppercase;
          opacity: 0.5;
        }
        &.bio {
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          width: 85%;
          margin: 20px 0;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .readMore {
        color: #fff;
        font-family: "DIN", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        &:hover {
          color: ${props => props.theme.colors.primary};
          transition: color 0.2s ease-in-out;
        }
      }
    }
    .avatars {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      z-index: 2;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .item {
        flex: 1;
        text-align: center;
        position: relative;
        cursor: pointer;
        &:hover {
          .bio {
            opacity: 1;
          }
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }
        .bio {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 92, 53, 0.78);
          transition: opacity 0.3s ease-in-out;
          p {
            font-weight: 700;
            margin: 0;
            &.name {
              font-size: 24px;
              line-height: 140%;
              letter-spacing: -0.015em;
            }
            &.position {
              font-size: 16px;
              line-height: 26px;
              text-transform: uppercase;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
`

export const DesktopView = styled.div`
  display: block;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`

export const MobileView = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: block;
  }
`