import styled from "styled-components"

import AngleDown from "./commons/icons/AngleDown"

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const AngleDownColumn = ({ items = 14 }) => {
  const renderItems = () => {
    let output = []

    for (let i = 0; i < items; i++) {
      output.push(<AngleDown key={i} color="#F88D2B" />)
    }

    return output
  }

  return (
    <Column>
      {renderItems()}
    </Column>
  )
}

export default AngleDownColumn
