import { useLayoutEffect, useRef } from "react";
import { useInView } from "react-intersection-observer"
import { gsap } from "gsap/all";
import styled from "styled-components";

import Avatars from "../components/Avatars";
import HomeSectionParallaxLayer from "../components/HomeSectionParallaxLayer";

import { getContextDelay } from "../helpers/animationHelper";
import useScroll from "../hooks/useScroll";

import logoMetaverseWorkshop from "../images/header/MetaverseWorkshopLogo.svg";
import labelMetaverseWorkshop from "../images/header/MetaverseLabelCentered.svg";

const Section = styled.section`
  /* height: 112vh; */
  height: 100vh;
  padding-top: 120px;
  position: relative;
  overflow-y: hidden;
  background: radial-gradient(
      59.41% 92.15% at 87.81% 7.77%,
      #6c088e 0%,
      rgba(11, 1, 52, 0.6) 100%
    ),
    radial-gradient(
      80.66% 83.61% at 5.78% 10.53%,
      #0fa5d5 0%,
      rgba(1, 52, 12, 0.4) 100%
    ),
    radial-gradient(54.84% 85.69% at 37.73% 77.03%, #083d8e 0%, #011234 100%);
  mix-blend-mode: normal;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 420px;
    padding-top: 85px;
  }
`;
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 95%;
    margin: 0 auto;
  }
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  .logo {
    width: 105px;
  }
  .label {
    width: 165px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`;
const Title = styled.h1`
  color: #fff;
  font-size: 80px;
  font-weight: 300;
  line-height: 70px;
  letter-spacing: -2px;
  text-transform: uppercase;
  text-align: center;
  margin: 55px 0 25px;

  background: linear-gradient(180deg, #ffffff -7.91%, #fccee1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 40px;
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 10px;
  }
`;
const Caption = styled.p`
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  margin: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 16px;
    line-height: 18px;
  }
`;
const ScrollDown = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  svg {
    animation: scroll 0.95s ease-in-out alternate infinite;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const HomeSection = () => {
  const containerRef = useRef(null);
  const wrapperRef = useRef(null);
  const { handleVisibleSection } = useScroll();

  const { ref, inView, entry } = useInView({
    threshold: 0,
    onChange: (inView, entry) => handleVisibleSection(inView, entry)
  })

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      getContextDelay(0.5).from(".logo", {
        y: 50,
        opacity: 0,
        ease: "power2",
        duration: 1.5,
      });

      getContextDelay(0.8).from(".label", {
        y: 50,
        opacity: 0,
        ease: "power",
        duration: 1.5,
      });
      getContextDelay(1.0).from(".title", {
        y: 50,
        opacity: 0,
        ease: "power",
        duration: 1.5,
      });
      getContextDelay(1.2).from(".caption", {
        y: 50,
        opacity: 0,
        ease: "power",
        duration: 1.5,
      });
      getContextDelay(1.4).from(".scroll", {
        y: 50,
        opacity: 0,
        ease: "power",
        duration: 1.5,
      });
      getContextDelay(0.5).from(".avatar-1", {
        y: 50,
        opacity: 0,
        ease: "power2",
        duration: 1.5,
      });
      getContextDelay(0.5).from(".avatar-1", {
        y: 50,
        opacity: 0,
        ease: "power2",
        duration: 1.5,
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  // shapes parallax effect
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(".frontLayer", { yPercent: 0 })
      gsap.set(".backLayer", { yPercent: 0 })

      gsap.to(".frontLayer", {
        yPercent: -300,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionWrapper",
          scrub: 1,
        },
      })
      gsap.to(".backLayer", {
        yPercent: -100,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionWrapper",
          scrub: 1,
        },
      })
    }, wrapperRef)

    return () => ctx.revert()
  }, [])

  return (
    <Section id="home" ref={ref}>
      <div className="sectionWrapper" ref={wrapperRef}>
        <SectionContent ref={containerRef}>
          <Logo>
            <img
              className="logo"
              src={logoMetaverseWorkshop}
              alt="Founders Workshop Metaverse Logo"
            />
            <img
              className="label"
              src={labelMetaverseWorkshop}
              alt="Metaverse Workshop"
            />
          </Logo>
          <Title className="title">
            This domain and web site <br />
            are for sale
          </Title>
          <Caption className="caption">
            Contact us at{" "}
            <a href="https://foundersworkshop.com/contact/" target="_blank" rel="noreferrer" style={{color: "#f88d2b", textDecoration: "none", fontStyle: "italic", fontWeight: "bold"}}>
              Founders Workshop
            </a>{" "}
          </Caption>
        </SectionContent>
        <Avatars  />
        <HomeSectionParallaxLayer />
      </div>
    </Section>
  );
};

export default HomeSection;
